import React, { useState, useEffect } from 'react';

import * as gs from "genericsuite";

const authenticationService = gs.authenticationService.authenticationService;
const console_debug_log = gs.loggingService.console_debug_log;

export const HomePage = () => {
    console_debug_log('>>>> FynApp HomePage <<<<');
    const [currentUser, setCurrentUser] = useState(authenticationService.currentUserValue);
    useEffect(() => {
        const subscription = authenticationService.currentUser.subscribe(
            x => setCurrentUser(x)
        );
        return () => subscription.unsubscribe();
    }, []);
    return (
        <gs.HomePage>
            <>
                <h2>Hi {currentUser.firstName}!</h2>
            </>
        </gs.HomePage>
    );
}
